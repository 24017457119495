/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Kunal's Portfolio",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Kunal Shah Portfolio",
    type: "website",
    url: "http://www.iamkunal.me",
  },
};

//Home Page
const greeting = {
  title: "Kunal Shah",
  logo_name: "Kunal Shah",
  nickname: "kunal",
  subTitle:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  resumeLink: "http://bit.ly/kunals-resume",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/imkunalshah",
  // linkedin: "https://www.linkedin.com/in/kunal-shah-7431a1182/",
  // gmail: "kunal.jack.shah@gmail.com",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/kshah_kunal"
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "http://bit.ly/kunal-github",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "http://bit.ly/kunal-linkedin",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  // {
  //   name: "YouTube",
  //   link: "https://www.youtube.com/channel/UC_amoXmmxSY9KusoDczDTXQ",
  //   fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
  //   backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  // },
  {
    name: "Gmail",
    link: "mailto:kunal.jack.shah@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Twitter",
    link: "http://bit.ly/kunal-twitter",
    fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
    backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  },
  {
    name: "Play Store",
    link: "http://bit.ly/kunal-play-apps",
    fontAwesomeIcon: "fa-google-play",
    backgroundColor: "#414141",
  },
  {
    name: "Resume",
    link: "http://bit.ly/kunals-resume",
    fontAwesomeIcon: "fa fa-file",
    backgroundColor: "#000000",
  },
  // {
  //   name: "Facebook",
  //   link: "https://www.facebook.com/laymanbrother.19/",
  //   fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
  //   backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  // },
  // {
  //   name: "Instagram",
  //   link: "https://www.instagram.com/layman_brother/",
  //   fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
  //   backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  // },
];

const skills = {
  data: [
    {
      title: "Android Development",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Developing highly responsive android apps",
        "⚡ Experience of working with International Clients and making e-commerce solutions",
        "⚡ Working with mvvm architecture",
      ],
      softwareSkills: [
        {
          skillName: "Android Studio",
          fontAwesomeClassname: "fa-android",
          style: {
            color: "green",
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "logos-java",
          style: {
            backgroundColor: "transparent",
            color: "#D00000",
          },
        },
        {
          skillName: "Kotlin",
          fontAwesomeClassname: "logos-kotlin",
          style: {
            backgroundColor: "transparent",
          },
        },
        // {
        //   skillName: "Python",
        //   fontAwesomeClassname: "ion-logo-python",
        //   style: {
        //     backgroundColor: "transparent",
        //     color: "#3776AB",
        //   },
        // },
      ],
    },
    {
      title: "Web Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building resposive website front end using VueJS,React-Redux",
        // "⚡ Developing mobile applications using Flutter, React Native and solo android apps using Kotlin",
        "⚡ Creating application backend in Node, Express & MySql/MongoDB",
      ],
      softwareSkills: [
        {
          skillName: "HTML",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "VueJS",
          fontAwesomeClassname: "simple-icons:vuejs",
          style: {
            color: "#3fba84",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        // {
        //   skillName: "Yarn",
        //   fontAwesomeClassname: "simple-icons:yarn",
        //   style: {
        //     color: "#2C8EBB",
        //   },
        // },
        // {
        //   skillName: "Gatsby",
        //   fontAwesomeClassname: "simple-icons:gatsby",
        //   style: {
        //     color: "#663399",
        //   },
        // },
        // {
        //   skillName: "Flutter",
        //   fontAwesomeClassname: "simple-icons:flutter",
        //   style: {
        //     color: "#02569B",
        //   },
        // },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
        "⚡ Deploying websites on cloud",
        "⚡ Creating Restful APIs using node.js,express.js and MongoDB/MySql and deploying that in cloud",
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        // {
        //   skillName: "Azure",
        //   fontAwesomeClassname: "simple-icons:microsoftazure",
        //   style: {
        //     color: "#0089D6",
        //   },
        // },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        // {
        //   skillName: "PostgreSQL",
        //   fontAwesomeClassname: "simple-icons:postgresql",
        //   style: {
        //     color: "#336791",
        //   },
        // },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        // {
        //   skillName: "Docker",
        //   fontAwesomeClassname: "simple-icons:docker",
        //   style: {
        //     color: "#1488C6",
        //   },
        // },
        // {
        //   skillName: "Kubernetes",
        //   fontAwesomeClassname: "simple-icons:kubernetes",
        //   style: {
        //     color: "#326CE5",
        //   },
        // },
      ],
    },
    // {
    //   title: "UI/UX Design",
    //   fileName: "DesignImg",
    //   skills: [
    //     "⚡ Designing highly attractive user interface for mobile and web applications",
    //     "⚡ Customizing logo designs and building logos from scratch",
    //   "⚡ Creating the flow of application functionalities to optimize user experience",
    // ],
    // softwareSkills: [
    //   {
    //     skillName: "Adobe XD",
    //     fontAwesomeClassname: "simple-icons:adobexd",
    //     style: {
    //       color: "#FF2BC2",
    //     },
    //   },
    //   {
    //     skillName: "Figma",
    //     fontAwesomeClassname: "simple-icons:figma",
    //     style: {
    //       color: "#F24E1E",
    //     },
    //   },
    //   {
    //       skillName: "Adobe Illustrator",
    //       fontAwesomeClassname: "simple-icons:adobeillustrator",
    //       style: {
    //         color: "#FF7C00",
    //       },
    //     },
    //     {
    //       skillName: "Inkscape",
    //       fontAwesomeClassname: "simple-icons:inkscape",
    //       style: {
    //         color: "#000000",
    //       },
    //     },
    //   ],
    // },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "http://bit.ly/kunal-hackerrank",
    },
    // {
    //   siteName: "Codechef",
    //   iconifyClassname: "simple-icons:codechef",
    //   style: {
    //     color: "#5B4638",
    //   },
    //   profileLink: "http://bit.ly/kunal-codechef",
    // },
    // {
    //   siteName: "Codeforces",
    //   iconifyClassname: "simple-icons:codeforces",
    //   style: {
    //     color: "#1F8ACB",
    //   },
    //   profileLink: "http://bit.ly/kunal-codeforces",
    // },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "https://www.hackerearth.com/@kshah26",
    },
    {
      siteName: "Leetcode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#FFA116",
      },
      profileLink: "http://bit.ly/kunal-leetcode",
    },
    // {
    //   siteName: "Kaggle",
    //   iconifyClassname: "simple-icons:kaggle",
    //   style: {
    //     color: "#20BEFF",
    //   },
    //   profileLink: "https://www.kaggle.com/laymanbrother",
    // },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Gujarat Technological University",
      subtitle: "B.E. in Computer Engineering",
      logo_path: "gtu_logo.jpg",
      alt_name: "GTU",
      duration: "2018 - 2022",
      descriptions: [
        "⚡ I have studied computer science fundamental subjects like DS, Algorithms, DBMS, OS, SE etc.",
        "⚡ Apart from this, I have done courses on Android Development, Web Development, Backend Development and Full Stack Development.",
        // "⚡ I was selected for Merit cum Means Scholarship which is given to top 10% of students in college. I have received award from respected director for consistently best performance in academics.",
      ],
      website_link: "http://gtu.ac.in",
    },
    {
      title: "Kokilaben Dhirubhai Ambani Vidyamandir",
      subtitle: "High School Certificate",
      logo_path: "kdav_logo.png",
      alt_name: "KDAV",
      duration: "2017 - 2018",
      descriptions: [
        // "⚡ I secured 70% in CBSE AISSCE Class XII Board Exams.",
        "⚡ I have studied basic secondary school subjects like English Core,Physics,Chemistry,Mathematics and Informatics Practices",
        // "⚡ Apart from this, I have done courses on Android Development, Web Development, Backend Development and Full Stack Development.",
        // "⚡ I was selected for Merit cum Means Scholarship which is given to top 10% of students in college. I have received award from respected director for consistently best performance in academics.",
      ],
      website_link: "http://bit.ly/kunal-kdav",
    },
    {
      title: "Kokilaben Dhirubahi Ambani Vidyamandir",
      subtitle: "Secondary School Certificate",
      logo_path: "kdav_logo.png",
      alt_name: "ADIT",
      duration: "2015 - 2016",
      descriptions: [
        // "⚡ I secured 7.8 CGPA in CBSE Class X Board Exams.",
        "⚡ I have studied basic secondary school subjects like History,Political Science,Geography,Science,Maths,English,Hindi",
        // "⚡ Apart from this, I have done courses on Android Development, Web Development, Backend Development and Full Stack Development.",
        // "⚡ I was selected for Merit cum Means Scholarship which is given to top 10% of students in college. I have received award from respected director for consistently best performance in academics.",
      ],
      website_link: "http://bit.ly/kunal-kdav",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Java(Basic)",
      subtitle: "Hackerrank",
      logo_path: "hackerrank_logo.png",
      certificate_link: "http://bit.ly/kunal-java-hr",
      alt_name: "Hackerrank",
      color_code: "transparent",
    },
    {
      title: "Java Level 1",
      subtitle: "Cambridge Certification Authority",
      logo_path: "cca_icon.png",
      certificate_link: "http://bit.ly/kunal-cca-java",
      alt_name: "cambridge",
      color_code: "white",
    },
    {
      title: "SQL(Basic)",
      subtitle: "HackerRank",
      logo_path: "hackerrank_logo.png",
      certificate_link: "https://www.hackerrank.com/certificates/e9caa5b3817c",
      alt_name: "hackerrank",
      color_code: "white",
    },
    // {
    //   title: "ML on GCP",
    //   subtitle: "- GCP Training",
    //   logo_path: "google_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/specialization/EB4VJARK8647",
    //   alt_name: "Google",
    //   color_code: "#0C9D5899",
    // },
    // {
    //   title: "Data Science",
    //   subtitle: "- Alex Aklson",
    //   logo_path: "ibm_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/specialization/PLEAPCSJBZT5",
    //   alt_name: "IBM",
    //   color_code: "#1F70C199",
    // },
    // {
    //   title: "Big Data",
    //   subtitle: "- Kim Akers",
    //   logo_path: "microsoft_logo.png",
    //   certificate_link:
    //     "https://drive.google.com/file/d/164zKCFOsI4vGqokc-Qj-e_D00kLDHIrG/view",
    //   alt_name: "Microsoft",
    //   color_code: "#D83B0199",
    // },
    // {
    //   title: "Advanced Data Science",
    //   subtitle: "- Romeo Kienzler",
    //   logo_path: "ibm_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/verify/BH2T9BRU87BH",
    //   alt_name: "IBM",
    //   color_code: "#1F70C199",
    // },
    // {
    //   title: "Advanced ML on GCP",
    //   subtitle: "- GCP Training",
    //   logo_path: "google_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/verify/5JZZM7TNQ2AV",
    //   alt_name: "Google",
    //   color_code: "#0C9D5899",
    // },
    // {
    //   title: "DL on Tensorflow",
    //   subtitle: "- Laurence Moroney",
    //   logo_path: "deeplearning_ai_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/verify/6T4DCUGNK8J8",
    //   alt_name: "deeplearning.ai",
    //   color_code: "#00000099",
    // },
    // {
    //   title: "Fullstack Development",
    //   subtitle: "- Jogesh Muppala",
    //   logo_path: "coursera_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/certificate/NRANJA66Y2YA",
    //   alt_name: "Coursera",
    //   color_code: "#2A73CC",
    // },
    // {
    //   title: "Kuberenetes on GCP",
    //   subtitle: "- Qwiklabs",
    //   logo_path: "gcp_logo.png",
    //   certificate_link:
    //     "https://google.qwiklabs.com/public_profiles/e4d5a92b-faf6-4679-a70b-a9047c0cd750",
    //   alt_name: "GCP",
    //   color_code: "#4285F499",
    // },
    // {
    //   title: "Cryptography",
    //   subtitle: "- Saurabh Mukhopadhyay",
    //   logo_path: "nptel_logo.png",
    //   certificate_link:
    //     "https://drive.google.com/open?id=1z5ExD_QJVdU0slLkp8CBqSF3-C3g-ro_",
    //   alt_name: "NPTEL",
    //   color_code: "#FFBB0099",
    // },
    // {
    //   title: "Cloud Architecture",
    //   subtitle: "- Qwiklabs",
    //   logo_path: "gcp_logo.png",
    //   certificate_link:
    //     "https://google.qwiklabs.com/public_profiles/5fab4b2d-be6f-408c-8dcb-6d3b58ecb4a2",
    //   alt_name: "GCP",
    //   color_code: "#4285F499",
    // },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work & Internship",
  description:
    "I have worked with many evolving startups as Android Developer. I have also worked as a freelancer and worked with international clients as an Android Dev.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Full Time",
      experiences: [
        {
          title: "Software Development Engineer",
          company: "Razorpay",
          company_url: "https://razorpay.com",
          logo_path: "razorpay-logo.png",
          duration: "Oct 2024 - Present",
          location: "Bengaluru, Karnataka, India",
          description: "Working with Razorpay as an SDE (android).",
          color: "#3395ff",
        },
        {
          title: "Software Development Engineer 1 (Android)",
          company: "Rooter",
          company_url:
            "https://play.google.com/store/apps/details?id=com.threesixteen.app",
          logo_path: "rooter-logo.png",
          duration: "Mar 2023 - Oct 2024",
          location: "New Delhi, India",
          description:
            "Working with Rooter as an android engineer and scaling and improving the app for more than 50 million app users.",
          color: "#ec4b3d",
        },
        {
          title: "Software Engineer (Android)",
          company: "Cricbuzz (Times Internet)",
          company_url:
            "https://play.google.com/store/apps/details?id=com.cricbuzz.android",
          logo_path: "cricbuzz-logo.jpg",
          duration: "Jun 2022 - Feb 2023",
          location: "Bengaluru, Karnataka, India",
          description:
            "Working with cricbuzz as an android engineer and scaling and improving the app for more than 100 million app users.",
          color: "#09ae84",
        },
        {
          title: "Android Developer | Web Developer | Backend Developer",
          company: "Freelancer",
          company_url: "https://freelancer.com/",
          logo_path: "freelancer_logo.svg",
          duration: "Jan 2019 - May 2021",
          location: "Remote",
          description:
            "Worked for the clients around the world and helped startups develop their applications.",
          color: "#0879bf",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Software Developer Intern (Android)",
          company: "Pratilipi",
          company_url: "http://www.pratilipi.com",
          logo_path: "pratilipi-logo.jpg",
          duration: "Feb 2022 - May 2022",
          location: "Bengaluru, Karnataka, India",
          description:
            "Working with the Pratilipi FM app and scaling and improving the app for more than 1 million app users.",
          color: "#d0031c",
        },
        {
          title: "Software Developer Intern",
          company: "Winuall",
          company_url: "http://www.winuall.com",
          logo_path: "winuall-logo.png",
          duration: "Oct 2021 - Jan 2022",
          location: "Bengaluru, Karnataka, India",
          description:
            "Developed android app using Kotlin and MVVM architecture and backend using node.js and mongoDB",
          color: "#0071C5",
        },
        {
          title: "Android Developer Intern",
          company: "Eople Internet Private Limited",
          company_url: "https://gympanzee.app",
          logo_path: "gympanzee-logo.png",
          duration: "Mar 2020 - Apr 2020",
          location: "Remote",
          description: "Worked on Android App named Gympanzee",
          color: "#ee3c26",
        },
      ],
    },
    // {
    //   title: "Volunteerships",
    //   experiences: [
    //     {
    //   title: "Google Explore ML Facilitator",
    //   company: "Google",
    //   company_url: "https://about.google/",
    //   logo_path: "google_logo.png",
    //   duration: "June 2019 - April 2020",
    //   location: "Hyderabad, Telangana",
    //   description:
    //     "Explore Machine Learning (ML) is a Google-sponsored program for university students to get started with Machine Learning. The curriculum offers 3 tracks of ML Content (Beginner, Intermediate, Advanced) and relies on university student facilitators to train other students on campus and to build opensource projects under this program.",
    //   color: "#4285F4",
    // },
    // {
    //   title: "Microsoft Student Partner",
    //   company: "Microsoft",
    //   company_url: "https://www.microsoft.com/",
    //   logo_path: "microsoft_logo.png",
    //   duration: "Aug 2019 - May 2020",
    //   location: "Hyderabad, Telangana",
    //   description:
    //     "Microsoft Student Partner is a program for university students to lead the awareness and use of Cloud especially Azure tools in the development of their projects and startups. Under this program, I have organised hands on workshops and seminars to teach Cloud Computing concepts to students.",
    //   color: "#D83B01",
    // },
    // {
    //   title: "Mozilla Campus Captain",
    //   company: "Mozilla",
    //   company_url: "https://www.mozilla.org/",
    //   logo_path: "mozilla_logo.png",
    //   duration: "Oct 2019 - May 2020",
    //   location: "Kurnool, Andhra Pradesh",
    //   description:
    //     "My responsibility for this program was to create opensource environment in college and in the city. We have organised multiple hackathons on the problems collected by ordinary people from Kurnool city. We have build opensource community of our own college. The community is available at dsc_iiitdmk on github.",
    //   color: "#000000",
    // },
    // {
    //   title: "Developer Students Club Member",
    //   company: "DSC IIITDM Kurnool",
    //   company_url:
    //     "https://www.linkedin.com/company/developer-students-club-iiitdm-kurnool",
    //   logo_path: "dsc_logo.png",
    //   duration: "Jan 2018 - May 2020",
    //   location: "Kurnool, Andhra Pradesh",
    //   description:
    //     "We have well established developer club in college which is directly associated with Google Developers. We have developed many interdisciplinary projects under the membership of this club. We have organised workshops and activities on Android Application Development, Flutter and React JS.",
    //   color: "#0C9D58",
    // },
    //     {
    //       title: "Developer Program Member",
    //       company: "Github",
    //       company_url: "https://github.com/",
    //       logo_path: "github_logo.png",
    //       duration: "July 2019 - PRESENT",
    //       location: "Work From Home",
    //       description:
    //         "I am actively contributing to many opensource projects. I have contributed to projects of organisations like Tensorflow, Uber, Facebook, Google, Scikit-learn, Kiwix, Sympy, Python, NVLabs, Fossasia, Netrack, Keras etc. These contributions include bug fixes, feature requests and formulating proper documentation for project.",
    //       color: "#181717",
    //     },
    //   ],
    // },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Android,web and backend projects and deploy them to play store,cloud services",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "kunal_profile.png",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with Android,Web, Cloud and Backend Development.",
  },
  // blogSection: {
  //   title: "Blogs",
  //   subtitle:
  //     "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
  //   avatar_image_path: "blogs_image.svg",
  // },
  addressSection: {
    title: "Address",
    subtitle: "Vadodara, Gujarat, India",
    avatar_image_path: "address_image.svg",
    location_map_link: "http://bit.ly/vadodara-location",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "+91 7016801408",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
};
